<template>
  <div>
    <div class="browser_pie_chart" ref="browser_pie_chart"></div>
  </div>
</template>
<script>
import echarts from 'echarts';
export default {
  props: ['transformedBrowserList'],
  data() {
    return {
      option: {
        title: {
          text: 'Browser',
          left: 'center',
          top: 0,
          paddingBottom: 20,
          textStyle: {
            fontFamily: 'poppins',
            fontSize: 18,
            fontWeight: 600,
            color: '#3C4549',
          }
        },
        legend: {
          top: 'bottom',
          orient: 'vertical',
        },
        tooltip: {
          trigger: 'item',
        },
        color: ['#013D87', '#2560D7', '#3AA956', '#719FFC', '#F9BC4D', '#D2D5DF', '#16a34a', '#4f46e5', '#9333ea', '#e11d48', '#ea580c', '#86efac'],
        textStyle: {
          fontFamily: 'poppins',
          fontSize: 13,
          fontWeight: 500,
          textAlign: 'center',
        },
        series: [
          {
            name: 'Analytics',
            type: 'pie',
            radius: ['30%', '70%'],
            center: ['50%', '50%'],
            roseType: 'area',
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            labelLine: {
              show: false,
            },
            itemStyle: {
              borderColor: 'white',
              borderWidth: 3,
            },
            data: this.transformedBrowserList.map((item) => ({
              value: item.value,
              name: item.name,
            })),
          },
        ],
      }

    }
  },
  mounted() {
    // Get a reference to the chart container
    const chartContainer = this.$refs.browser_pie_chart;

    // Initialize ECharts instance
    const chart = echarts.init(chartContainer);

    // Set the ECharts options
    chart.setOption(this.option);

    // Resize the chart when the window is resized
    window.addEventListener('resize', async () => {
      await chart.resize();
    });
  },
}
</script>

<style lang="less">
.browser_pie_chart {
  width: 100%;
  height: 400px;
}
</style>
